import React from "react";
import { Link } from "gatsby";
import { PortableText as BasePortableText } from "@portabletext/react";

import Image from "./image";
import Youtube from "./youtube";

function AutoLink({ children, value }) {
  if (value.href.match(/^(https?)?:\/\//)) {
    return (
      <a href={value.href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }
  return <Link to={value.href}>{children}</Link>;
}

const components = {
  types: {
    mainImage: Image,
    youtube: Youtube
  },
  marks: {
    link: AutoLink
  }
};

const PortableText = ({ blocks }) => {
  return <BasePortableText value={blocks} components={components} />;
};

export default PortableText;
