import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import getYouTubeId from "get-youtube-id";
import YouTube from "react-youtube";

import LayoutContext from "../contexts/layout";
import { useKeyPresses } from "../util/hooks";

import play from "../images/play.svg";
import * as styles from "./image.module.css";

const Youtube = ({ value: { url, title } }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isLightboxOpen, setIsLightboxOpen } = useContext(LayoutContext);

  const closeLightbox = useCallback(() => {
    setIsOpen(false);
    setIsLightboxOpen(false);
  }, [setIsLightboxOpen]);
  useKeyPresses({ Escape: { onDown: closeLightbox } });

  // Just in case the lightbox gets closed elsewhere…
  useEffect(() => {
    if (isOpen && !isLightboxOpen) {
      setIsOpen(false);
    }
  }, [isOpen, setIsOpen, isLightboxOpen]);

  const id = useMemo(() => getYouTubeId(url), [url]);
  return (
    <>
      <div className={styles.youtubeThumbnail}>
        <img
          src={`https://img.youtube.com/vi/${id}/hqdefault.jpg`}
          alt={title}
          onClick={() => {
            setIsOpen(true);
            setIsLightboxOpen(true);
          }}
          className={styles.youtubeThumbnailImage}
        />
        <img src={play} alt="" className={styles.youtubePlay} />
      </div>
      {isOpen && isLightboxOpen && (
        <div className={styles.lightbox}>
          <YouTube
            containerClassName={styles.youtubeContainer}
            className={styles.youtube}
            videoId={id}
            onEnd={closeLightbox}
          />
          <button
            title="Close"
            aria-label="Close"
            className={styles.x}
            onClick={closeLightbox}
          />
        </div>
      )}
    </>
  );
};

export default Youtube;
