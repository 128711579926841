import React, { useCallback, useContext, useEffect, useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "gatsby-source-sanity";

import LayoutContext from "../contexts/layout";
import clientConfig from "../../client-config";
import { useKeyPresses } from "../util/hooks";

import * as styles from "./image.module.css";

const Image = ({ value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isLightboxOpen, setIsLightboxOpen } = useContext(LayoutContext);

  const closeLightbox = useCallback(() => {
    setIsOpen(false);
    setIsLightboxOpen(false);
  }, [setIsOpen, setIsLightboxOpen]);
  useKeyPresses({ Escape: { onDown: closeLightbox } });

  // Just in case the lightbox gets closed elsewhere…
  useEffect(() => {
    if (isOpen && !isLightboxOpen) {
      setIsOpen(false);
    }
  }, [isOpen, setIsOpen, isLightboxOpen]);

  if (!value || !value.asset || !value.asset._id) {
    return null;
  }
  const gatsbyImageData = getGatsbyImageData(
    value,
    { height: 600 },
    clientConfig.sanity
  );
  const gatsbyImageDataFull = getGatsbyImageData(
    value,
    {},
    clientConfig.sanity
  );
  return (
    <>
      <GatsbyImage
        image={gatsbyImageData}
        alt={value.alt}
        onClick={() => {
          setIsOpen(true);
          setIsLightboxOpen(true);
        }}
        className={styles.image}
      />
      {isOpen && isLightboxOpen && (
        <div className={styles.lightbox}>
          <GatsbyImage
            image={gatsbyImageDataFull}
            alt={value.alt}
            style={{ height: "100%", width: "100%" }}
            objectFit="contain"
          />
          <button
            title="Close"
            aria-label="Close"
            className={styles.x}
            onClick={closeLightbox}
          />
        </div>
      )}
    </>
  );
};

export default Image;
